<template>
  <svg
    class="logo"
    viewBox="0 0 213 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M132.5 5.19999L132.8 5.49999C139.8 12.5 139.8 23.7 132.8 30.7L94.4 69C108.5 54.9 108.5 32.1 94.4 18.1L107.3 5.19999C114.3 -1.70001 125.5 -1.70001 132.5 5.19999Z"
      fill="#46C8F0"
    />
    <path
      d="M94.4 18.1C108.5 32.2 108.5 55 94.4 69C80.3 83 57.6 83 43.5 69L94.4 18.1Z"
      fill="#288CE6"
    />
    <path
      d="M94.4 18.1L43.5 69C29.4 54.9 29.4 32.1 43.5 18.1C57.6 4.1 80.4 4 94.4 18.1Z"
      fill="#FF3C5A"
    />
    <path
      d="M43.5 18.1C29.4 32.2 29.4 55 43.5 69L30.7 81.8C23.7 88.8 12.5 88.8 5.50001 81.8L5.20001 81.5C-1.79999 74.5 -1.79999 63.3 5.20001 56.3L43.5 18.1Z"
      fill="#FFA000"
    />
    <path
      d="M211.6 74.7C211.7 74.6 211.9 74.6 212.1 74.6C212.3 74.7 212.6 74.8 212.7 74.9C212.9 75.1 212.9 75.3 212.8 75.5C212.2 76.5 211.6 77.5 210.8 78.7C210.1 79.9 209.3 81 208.4 82C207.6 83 206.7 83.7 205.9 84.1C205.2 84.4 204.5 84.3 203.9 83.9C203.3 83.5 202.9 83 202.8 82.4C202.8 82 202.8 81.6 202.9 81C203 80.4 203.2 79.9 203.3 79.3C202.9 79.7 202.5 80.2 202.1 80.8C201.3 82 200.3 83.1 199.2 84.2C198.9 84.4 198.5 84.5 197.9 84.3C197.3 84.1 196.8 83.9 196.4 83.6C195.9 83.2 195.7 82.9 195.7 82.4C195.9 80.7 196.5 78.9 197.5 77.1C198.5 75.3 199.6 73.8 200.8 72.8C201.5 72.3 202.1 72.1 202.6 72.3C203.1 72.5 203.7 72.7 204.3 73.1C204.5 73.2 204.7 73.4 204.9 73.5C205.2 73.2 205.5 72.9 205.7 72.5C205.9 72.3 206.1 72 206.3 71.8C206.6 71.6 206.9 71.5 207.4 71.7C207.9 71.8 208.2 72.1 208.6 72.4C209 72.7 209 73.1 208.8 73.4C207.3 75.9 206.4 77.9 206 79.2C205.6 80.6 205.6 81.2 205.9 81.2C207.2 81.4 209 79.1 211.6 74.7ZM203.7 75.9C203.8 75.8 203.8 75.6 203.8 75.4C203.8 75.2 203.8 75 203.7 74.8C203.6 74.6 203.5 74.5 203.4 74.5C203.2 74.5 203 74.6 202.8 74.8C201.8 75.9 201 77.1 200.3 78.4C199.6 79.8 199.3 80.7 199.2 81.3C199.7 80.8 200.5 80 201.4 78.9C202.4 77.8 203.2 76.8 203.7 75.9Z"
      fill="black"
    />
    <path
      d="M199.2 75.2C199.3 75 199.5 74.9 199.7 75C199.9 75 200.1 75.2 200.3 75.4C200.5 75.6 200.5 75.8 200.5 76C199.8 77.7 198.8 79.3 197.7 80.8C196.6 82.3 195.6 83.3 194.7 83.8C194.3 84 193.9 84 193.3 83.9C192.8 83.8 192.3 83.6 191.9 83.4C191.5 83.1 191.2 82.8 191.1 82.5C191 81.8 191.1 80.9 191.4 79.8C191.7 78.7 192 77.8 192.3 77.2C192.4 77 192.4 76.9 192.4 76.8C191.4 77.7 190.4 79 189.2 80.8C188 82.6 187.1 84.1 186.6 85.2C186.4 85.5 186.1 85.6 185.7 85.5C185.2 85.4 184.8 85.3 184.4 85.1C184 84.9 183.8 84.6 183.9 84.3C184.3 82.9 185 80.8 186.1 78.1C187.2 75.4 188.4 72.7 189.7 70.1C191 67.5 192.2 65.6 193.3 64.6C193.7 64.3 194.2 64.2 194.7 64.3C195.2 64.4 195.7 64.7 196 65.1C196.3 65.5 196.4 65.8 196.2 66.1C195.8 66.3 195.2 67 194.2 68.2C193.2 69.4 192.3 71 191.3 72.8C190.3 74.7 189.4 76.7 188.6 78.8C189.3 77.7 190.1 76.7 191 75.6C191.9 74.5 192.7 73.7 193.5 73.2C193.9 73 194.4 72.9 194.9 73.1C195.4 73.2 195.8 73.5 196.1 73.9C196.4 74.3 196.5 74.6 196.4 75C196.2 75.3 196.1 75.7 195.8 76.2C195.3 77.1 194.9 78.1 194.5 79.2C194.1 80.3 193.8 81.2 193.8 81.9C194.8 81.4 195.8 80.5 196.7 79.2C197.5 78 198.4 76.7 199.2 75.2Z"
      fill="black"
    />
    <path
      d="M186.5 75.1C186.8 75.1 187.2 75.2 187.5 75.3C187.9 75.5 188.1 75.7 188.3 75.8C188.2 75.9 188.2 75.9 188.2 75.9H188.1H188C188 75.9 187.9 75.9 187.8 75.9C187.7 75.9 187.6 75.9 187.5 75.9C187 76 186.4 76.4 185.5 76.9C184.6 77.4 183.7 78 182.9 78.6C182.1 79.2 181.5 79.6 181.2 79.9C181.3 80 181.3 80.1 181.4 80.2C181.6 80.4 181.7 80.6 181.8 80.9C182.2 81.7 182.3 82.5 182.1 83.2C181.9 84 181.5 84.7 180.9 85.4C180.3 86.1 179.6 86.6 178.8 87.1C178 87.5 177 87.7 176.1 87.7C175.1 87.6 174.4 87.3 173.9 86.8C173.4 86.2 173.4 85.4 174 84.3C174.5 83.5 175.1 82.7 175.9 82C176.7 81.3 177.5 80.6 178.3 80C178.1 79.8 178 79.6 177.9 79.4C177.6 79 177.2 78.5 177 78.1C176.7 77.7 176.5 77.2 176.4 76.7C176.2 75.6 176.3 74.5 176.8 73.4C177.3 72.3 178 71.5 179 70.8C179.3 70.6 179.7 70.5 180.1 70.6C180.5 70.7 181 70.8 181.4 71C181.8 71.2 182.1 71.4 182.3 71.5C182.9 70.6 183.4 69.9 183.8 69.4C184.2 68.9 184.6 68.6 184.8 68.4C185 68.3 185.2 68.3 185.4 68.5C185.6 68.7 185.9 68.9 186.1 69.2C186.3 69.5 186.5 69.8 186.6 70C186.7 70.2 186.7 70.5 186.6 70.6C186.1 71 185.6 71.5 184.9 72.2C184.2 72.9 183.7 73.7 183.3 74.5C182.9 75.3 182.8 75.9 182.9 76.4C182.6 76.5 182.3 76.3 181.9 76.1C181.5 75.8 181.2 75.4 181.1 75C181 74.6 181.1 74.2 181.2 73.7C181.4 73.2 181.5 72.9 181.6 72.6C181 72.6 180.6 73 180.1 73.7C179.7 74.4 179.5 75.3 179.4 76.2C179.3 77.2 179.6 78 180.1 78.7C180.5 78.5 180.9 78.2 181.3 77.9C182.1 77.3 183 76.7 183.9 76.2C185.1 75.6 185.9 75.3 186.5 75.1ZM176 85.8C176.4 85.7 176.9 85.5 177.5 85C178.1 84.5 178.5 84 178.9 83.3C179.3 82.7 179.4 82.2 179.2 81.8C179.1 81.9 179.1 81.9 179 82C178.4 82.6 177.8 83.2 177.2 83.8C176.7 84.3 176.3 85 176 85.8Z"
      fill="black"
    />
    <path
      d="M175.7 78.2C175.7 78.4 175.8 78.6 175.9 78.6C176 78.7 176.1 78.7 176.2 78.8C176.3 78.8 176.3 78.9 176.4 78.9C176.4 78.9 176.4 79 176.4 79.1C176.3 79.3 176 79.4 175.5 79.4C175 79.4 174.5 79.3 174 79.1C173.5 78.9 173.2 78.7 173.1 78.4C173 77.9 172.9 77.3 173 76.7C173 76.4 173 76.2 173 75.9C171.9 77.1 170.9 78.5 170.1 80C169.3 81.5 168.6 83.2 168.1 85.1C168 85.4 167.7 85.5 167.3 85.5C166.9 85.4 166.5 85.3 166.1 85C165.7 84.7 165.5 84.4 165.6 84.1C166 81.7 166.7 79.2 167.7 76.7C168.7 74.2 169.7 71.9 170.8 70.1C170.9 69.9 171.1 69.8 171.3 69.8C171.5 69.8 171.7 69.8 171.9 69.8C172.9 70.1 173.3 70.6 173 71.3C172.8 71.7 172.6 72.1 172.4 72.5C172 73.4 171.5 74.3 171.1 75.4C171.1 75.4 171.2 75.3 171.4 75C171.6 74.7 171.8 74.4 172 74.1C172.2 73.8 172.4 73.4 172.6 73.2C172.8 72.9 172.9 72.7 173 72.7C173.2 72.4 173.4 72.3 173.5 72.2C173.6 72.1 173.9 72.2 174.2 72.3C174.4 72.4 174.7 72.5 174.9 72.5C175.4 72.7 175.7 72.8 176 72.9C176.2 73 176.3 73.2 176.3 73.5C176.3 73.8 176.3 74.2 176.2 74.7C176.2 74.9 176.2 75.1 176.1 75.3C175.6 76.3 175.6 77.2 175.7 78.2Z"
      fill="black"
    />
    <path
      d="M168.6 75.6C168.7 75.4 168.9 75.3 169.1 75.4C169.3 75.4 169.5 75.5 169.6 75.6C169.7 75.7 169.8 75.9 169.7 76C169.4 76.7 168.9 77.6 168.2 78.7C167.5 79.8 166.8 80.8 166 81.8C165.2 82.8 164.5 83.4 163.9 83.7C163.5 83.9 163.1 83.9 162.6 83.8C162.1 83.7 161.7 83.5 161.3 83.2C160.9 82.9 160.8 82.5 160.8 82C160.9 81 161.2 79.7 161.7 78C162.3 76.3 162.9 74.8 163.6 73.6C163.8 73.4 164.1 73.3 164.6 73.4C165.1 73.5 165.5 73.6 165.9 73.9C166.3 74.2 166.4 74.4 166.3 74.8C165.3 76.7 164.6 78.2 164.3 79.4C164 80.6 163.8 81.2 163.9 81.5C164.1 81.9 164.6 81.6 165.5 80.5C166.4 79.4 167.4 77.7 168.6 75.6ZM166.6 72.1C166.4 72.3 166.2 72.5 165.8 72.4C165.4 72.4 165.1 72.3 164.8 72.1C164.5 71.9 164.4 71.7 164.4 71.5C164.5 70.8 164.7 70.2 164.9 69.6C165.1 69 165.3 68.5 165.5 68.3C165.7 68 166.1 67.9 166.5 68C167 68.1 167.4 68.2 167.8 68.5C168.2 68.8 168.3 69 168.1 69.4C168 69.6 167.9 69.8 167.8 70C167.6 70.4 167.4 70.7 167.2 71.1C167 71.5 166.8 71.9 166.6 72.1Z"
      fill="black"
    />
    <path
      d="M146.6 72.1C146.5 72.5 146.6 73 146.8 73.6C147 74.2 147.2 74.7 147.5 75.3C147.8 75.8 148 76.2 148.2 76.4C148.4 76.1 148.8 75.4 149.2 74.3C149.7 73.2 150.2 72 150.7 70.6C151.2 69.3 151.6 68.1 151.9 66.8C152.2 65.6 152.4 64.5 152.5 63.6C152.6 62.7 152.4 62.1 152 61.9C151.3 61.5 150.4 61.7 149.3 62.4C148.2 63.1 147.2 64.1 146.1 65.5C145 66.9 144 68.4 143.1 70C142.2 71.6 141.5 73.2 141.1 74.7C140.7 76.2 140.6 77.5 140.9 78.4C141.2 79.3 142 79.9 143.3 79.9C142.5 80.3 141.8 80.5 141 80.4C140.2 80.4 139.5 80.1 138.9 79.6C138.5 79.2 138.1 78.6 138 77.9C137.9 77.4 137.8 76.9 137.9 76.3C137.9 75.5 138.1 74.5 138.5 73.3C139 71.8 139.7 70.3 140.6 68.6C141.5 66.9 142.6 65.4 143.8 64C145.1 62.6 146.4 61.5 147.9 60.9C149.4 60.2 151 60.2 152.7 60.7C153.9 61.2 154.6 62.1 154.9 63.5C155.1 64.9 155 66.4 154.6 68.1C154.2 69.8 153.7 71.4 153 73C152.4 74.5 151.9 75.7 151.4 76.5C151.7 76.2 152.4 75.6 153.5 74.6C154.6 73.6 155.8 72.3 157.1 70.9C158.4 69.5 159.6 68.1 160.7 66.7C161.8 65.3 162.5 64.1 162.8 63.2C163.1 62.3 162.9 61.7 162.1 61.6C162.6 61.2 163.2 61.1 163.9 61.3C164.6 61.5 165.1 61.9 165.5 62.4C165.9 63 166.1 63.6 165.9 64.3C165.4 65.8 164.3 67.5 162.5 69.4C160.7 71.3 158.7 73.1 156.5 74.9C154.3 76.7 152.4 78.2 150.8 79.3C151.4 80 152.4 80.9 153.8 82C155.2 83.1 156.7 84.1 158.2 85C159.7 85.9 160.9 86.5 161.9 86.7C162.4 86.8 162.9 86.7 163.4 86.5C164 86.3 164.3 86 164.5 85.6C164.7 85.6 164.7 85.9 164.6 86.4C164.5 86.9 164.2 87.5 163.8 88.1C163.4 88.7 162.9 89.2 162.2 89.6C161.6 90 160.8 90.1 160 89.8C158.7 89.3 157.3 88.6 155.8 87.6C154.3 86.6 153 85.5 151.8 84.4C150.6 83.3 149.6 82.3 148.9 81.6C148.5 82.3 148 83.3 147.3 84.5C146.6 85.7 146 86.5 145.4 87C145.1 87.2 144.7 87.2 144.1 87C143.5 86.9 142.9 86.6 142.5 86.3C142 86 141.9 85.7 142.1 85.4C143 84.9 143.9 83.9 144.8 82.5C145.6 81.4 146.3 80.3 146.8 79.3C146.6 79 146.3 78.5 146 78C145.7 77.5 145.5 76.9 145.3 76.2C145.1 75.5 145.1 74.8 145.3 74.1C145.5 73.6 145.9 72.9 146.6 72.1Z"
      fill="black"
    />
    <path
      d="M128.5 74.7C128.6 74.6 128.8 74.6 129 74.6C129.2 74.7 129.5 74.8 129.6 74.9C129.8 75.1 129.8 75.3 129.7 75.5C129.1 76.5 128.5 77.5 127.7 78.7C127 79.9 126.2 81 125.3 82C124.5 83 123.6 83.7 122.8 84.1C122.1 84.4 121.4 84.3 120.8 83.9C120.2 83.5 119.8 83 119.7 82.4C119.7 82 119.7 81.6 119.8 81C119.9 80.4 120.1 79.9 120.2 79.3C119.8 79.7 119.4 80.2 119 80.8C118.2 82 117.2 83.1 116.1 84.2C115.8 84.4 115.4 84.5 114.8 84.3C114.2 84.1 113.7 83.9 113.3 83.6C112.8 83.2 112.6 82.9 112.6 82.4C112.8 80.7 113.4 78.9 114.4 77.1C115.4 75.3 116.5 73.8 117.7 72.8C118.4 72.3 119 72.1 119.5 72.3C120 72.5 120.6 72.7 121.2 73.1C121.4 73.2 121.6 73.4 121.8 73.5C122.1 73.2 122.4 72.9 122.6 72.5C122.8 72.3 123 72 123.2 71.8C123.5 71.6 123.8 71.5 124.3 71.7C124.8 71.8 125.1 72.1 125.5 72.4C125.9 72.7 125.9 73.1 125.7 73.4C124.2 75.9 123.3 77.9 122.9 79.2C122.5 80.6 122.5 81.2 122.8 81.2C124.1 81.4 125.8 79.1 128.5 74.7ZM120.6 75.9C120.7 75.8 120.7 75.6 120.7 75.4C120.7 75.2 120.7 75 120.6 74.8C120.5 74.6 120.4 74.5 120.3 74.5C120.1 74.5 119.9 74.6 119.7 74.8C118.7 75.9 117.9 77.1 117.2 78.4C116.5 79.8 116.2 80.7 116.1 81.3C116.6 80.8 117.4 80 118.3 78.9C119.2 77.8 120 76.8 120.6 75.9Z"
      fill="black"
    />
    <path
      d="M118.8 66.7C119.7 66.9 120.4 67 120.9 67.2C121.4 67.4 121.8 67.6 122.1 67.9C122.2 68.1 122.4 68.4 122.5 68.7C122.6 69.1 122.7 69.4 122.7 69.7C122.7 70 122.6 70.2 122.4 70.2C122.1 69.8 121.4 69.4 120.5 69.2C119 68.9 117.9 68.7 117.2 68.8C116.9 69.3 116.5 69.8 116.2 70.4C115.2 72.1 114.3 74 113.5 76C112.7 78 112.1 79.9 111.7 81.7C111.3 83.5 111.2 85 111.3 86.2C111.4 87.4 111.9 88 112.8 88.1C112.5 88.4 112 88.5 111.4 88.4C110.8 88.3 110.2 88 109.6 87.4C109 86.8 108.6 86 108.5 84.9C108.3 83.2 108.8 80.9 109.7 78C110.7 75.1 111.8 72.3 113.2 69.7C113.3 69.5 113.4 69.3 113.5 69.1C113.3 69.1 113 69.2 112.6 69.2C112.2 69.3 111.8 69.4 111.5 69.5C111.2 69.6 110.9 69.7 110.9 69.9C110.8 70.1 110.7 70.1 110.5 70.1C110.4 70 110.2 69.9 110.1 69.7C110 69.5 109.9 69.3 109.8 69.1C109.7 68.9 109.6 68.7 109.6 68.6C109.5 68.4 109.5 68.1 109.7 68C109.8 67.8 110 67.7 110.2 67.6C110.8 67.3 111.5 67.1 112.3 67C113.1 66.9 113.8 66.8 114.4 66.8C114.5 66.8 114.7 66.8 114.9 66.8C115.6 65.8 116.3 64.9 117 64.1C117.2 63.9 117.6 63.9 117.9 64C118.2 64.1 118.6 64.3 119 64.6C119.4 64.9 119.6 65.2 119.6 65.5C119.7 65.8 119.6 66 119.5 66.1C119.2 66.2 119 66.5 118.8 66.7Z"
      fill="black"
    />
    <path
      d="M110.5 75.6C110.6 75.4 110.8 75.3 111 75.4C111.2 75.4 111.4 75.5 111.5 75.6C111.6 75.7 111.7 75.9 111.6 76C111.3 76.7 110.8 77.6 110.1 78.7C109.4 79.8 108.7 80.8 107.9 81.8C107.1 82.8 106.4 83.4 105.8 83.7C105.4 83.9 105 83.9 104.5 83.8C104 83.7 103.6 83.5 103.2 83.2C102.8 82.9 102.7 82.5 102.7 82C102.8 81 103.1 79.7 103.6 78C104.2 76.3 104.8 74.8 105.5 73.6C105.7 73.4 106 73.3 106.5 73.4C107 73.5 107.4 73.6 107.8 73.9C108.2 74.2 108.3 74.4 108.2 74.8C107.2 76.7 106.5 78.2 106.2 79.4C105.9 80.6 105.7 81.2 105.8 81.5C106 81.9 106.5 81.6 107.4 80.5C108.3 79.4 109.3 77.7 110.5 75.6ZM108.4 72.1C108.2 72.3 108 72.5 107.6 72.4C107.2 72.4 106.9 72.3 106.6 72.1C106.3 71.9 106.2 71.7 106.2 71.5C106.3 70.8 106.5 70.2 106.7 69.6C106.9 69 107.1 68.5 107.3 68.3C107.5 68 107.9 67.9 108.3 68C108.8 68.1 109.2 68.2 109.6 68.5C110 68.8 110.1 69 109.9 69.4C109.8 69.6 109.7 69.8 109.6 70C109.4 70.4 109.2 70.7 109 71.1C108.8 71.5 108.6 71.9 108.4 72.1Z"
      fill="black"
    />
    <path
      d="M104.5 65.6C103.8 65.9 102.8 67 101.7 68.7C100.5 70.4 99.4 72.1 98.4 73.9C97.4 75.7 96.8 76.9 96.7 77.5C97.4 76.6 98.2 75.6 99.1 74.6C100 73.6 100.9 72.7 101.7 72C101.9 71.9 102 71.8 102.2 71.7C102.4 71.6 102.6 71.6 102.8 71.7C103.4 72 103.9 72.3 104.1 72.5C104.4 72.8 104.3 73.2 104.1 73.6C103.5 74.5 102.6 75.6 101.4 77C100.2 78.3 99.3 79.3 98.6 80C99.3 80 100 80 100.6 80.1C101.2 80.2 101.6 80.4 101.9 80.6C102 80.7 102 80.9 101.9 81.2C101.8 81.5 101.7 81.8 101.5 82.1C101.3 82.4 101.1 82.7 100.9 82.8C100.7 83 100.5 83 100.3 82.9C99.7 82.4 98.8 82.2 97.7 82.1C96.6 82 95.6 82.3 94.7 82.8C94.4 83.7 94.2 84.4 94.1 84.9C94 85.2 93.7 85.3 93.3 85.3C92.8 85.3 92.4 85.1 92 84.9C91.6 84.7 91.4 84.4 91.4 84.1C91.8 82.7 92.5 80.7 93.7 78C94.9 75.3 96.1 72.6 97.5 70C98.9 67.4 100.2 65.4 101.4 64.2C101.8 63.9 102.1 63.7 102.4 63.8C102.7 63.9 103.1 64 103.6 64.4C103.7 64.5 103.8 64.5 103.9 64.6C104.1 64.7 104.3 64.9 104.5 65C104.7 65.1 104.6 65.4 104.5 65.6ZM100.2 74.7C99.3 75.5 98.4 76.4 97.5 77.5C96.6 78.6 96 79.5 95.6 80.3C96.5 79.4 97.4 78.4 98.3 77.3C99.2 76.2 99.9 75.2 100.2 74.7Z"
      fill="black"
    />
    <path
      d="M95 75.6C95.1 75.4 95.3 75.3 95.5 75.4C95.7 75.4 95.9 75.5 96 75.6C96.1 75.7 96.2 75.9 96.1 76C95.8 76.7 95.3 77.6 94.6 78.7C93.9 79.8 93.2 80.8 92.4 81.8C91.6 82.8 90.9 83.4 90.3 83.7C89.9 83.9 89.5 83.9 89 83.8C88.5 83.7 88.1 83.5 87.7 83.2C87.3 82.9 87.2 82.5 87.2 82C87.3 81 87.6 79.7 88.1 78C88.7 76.3 89.3 74.8 90 73.6C90.2 73.4 90.5 73.3 91 73.4C91.5 73.5 91.9 73.6 92.3 73.9C92.7 74.2 92.8 74.4 92.7 74.8C91.7 76.7 91 78.2 90.7 79.4C90.4 80.6 90.2 81.2 90.3 81.5C90.5 81.9 91 81.6 91.9 80.5C92.8 79.3 93.8 77.7 95 75.6ZM93 72.1C92.8 72.3 92.6 72.5 92.2 72.4C91.8 72.4 91.5 72.3 91.2 72.1C90.9 71.9 90.8 71.7 90.8 71.5C90.9 70.8 91.1 70.2 91.3 69.6C91.5 69 91.7 68.5 91.9 68.3C92.1 68 92.5 67.9 92.9 68C93.3 68.1 93.8 68.2 94.2 68.5C94.6 68.8 94.7 69 94.5 69.4C94.4 69.6 94.3 69.8 94.2 70C94 70.4 93.8 70.7 93.6 71.1C93.4 71.5 93.1 71.9 93 72.1Z"
      fill="black"
    />
    <path
      d="M86.2 88.4C85.4 89 84.6 89.3 83.7 89.2C82.8 89.1 82.1 88.7 81.4 87.9C80.7 87.1 80.5 86 80.5 84.6C80.6 83.2 80.8 81.6 81.3 79.9C81.8 78.2 82.3 76.5 82.9 74.7C83.6 72.8 84.1 71 84.5 69.3C84.9 67.5 84.9 66.5 84.6 66.1C84.3 65.7 83.7 65.9 82.9 66.6C82.1 67.3 81.3 68.4 80.3 69.9C79.2 71.7 78 73.9 76.8 76.6C75.6 79.3 74.3 82.5 73.1 86.4C73 86.7 72.7 86.8 72.3 86.8C71.9 86.8 71.4 86.7 71 86.5C70.5 86.3 70.2 86 69.9 85.6C69.6 85.2 69.6 84.8 69.8 84.4C70.1 83.9 70.5 82.9 71.1 81.4C71.7 79.9 72.4 78.1 73.1 76.2C73.8 74.3 74.4 72.3 74.9 70.4C75.4 68.5 75.6 67 75.6 65.7C75.6 64.5 75.2 63.8 74.4 63.8C73.6 63.9 72.7 64.4 71.8 65.4C70.9 66.4 70 67.7 69.2 69.3C68.4 70.8 67.8 72.4 67.4 73.9C67 75.5 66.9 76.8 67.2 77.9C67.4 78.5 67.7 78.8 68.2 79C68.7 79.2 69.2 79.1 69.8 78.7C69.6 79.2 69.2 79.6 68.5 79.8C67.8 80 67.1 80.1 66.3 79.9C65.5 79.7 64.9 79.2 64.4 78.5C63.9 77.7 63.8 76.6 64.1 75.2C64.5 73.2 65.2 71.2 66.2 69.2C67.2 67.2 68.5 65.5 69.9 64.1C71.4 62.7 72.9 62 74.6 62C76 62 76.9 62.5 77.4 63.5C77.9 64.5 78 65.5 77.9 66.8C77.8 68.1 77.6 69.3 77.3 70.4C77 71.5 76.7 72.4 76.5 72.8V72.7C76.9 71.9 77.4 70.9 78 69.6C78.7 68.3 79.5 67.1 80.4 65.8C81.3 64.6 82.3 63.6 83.4 63C84.5 62.4 85.6 62.3 86.7 62.8C87.5 63.1 88 64.1 88.1 65.6C88.2 67.1 87.8 69.3 86.9 72.2C86.8 72.4 86.8 72.7 86.7 72.9C86.1 74.8 85.5 76.7 85 78.5C84.5 80.3 84.1 82.1 83.8 83.6C83.5 85.1 83.6 86.3 83.8 87.2C84.6 88.2 85.2 88.6 86.2 88.4Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style lang="scss" scoped>
.logo {
  height: 3em;
  user-select: none;
  -webkit-user-drag: none;
}

@media (prefers-color-scheme: dark) {
  .logo {
    path[fill="black"] {
      fill: var(--white);
    }
  }
}
</style>
